/* Theme blue: #0ED7BA */
body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    /* max-width: 100vw; */
    background-color: #0E1117;

    padding-left: 80px;
    padding-right: 80px;
    /* padding-bottom: 50px; */
}

/* Styles for wider screens */
@media screen and (min-width: 1000px) {
    .App{
        align-items: top;
        display: flex;
    }
    #input, #output{
        width: 50%
    }
    #input{
        padding-right: 50px;
    }
    /* body {
        background-color: lightblue;
    } */
}

/* Styles for smaller screens */
@media screen and (max-width: 999px) {
    /* body {
        background-color: lightcoral;
    } */
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

pre, code, textarea, select, button{
    overflow: auto;
    margin: 5px;
    border-radius: 8px;
    color: #FAFAFA;
    background-color: #262730;
}

pre, code{
    display: block;
    padding: 8px;
}

button{
    box-shadow: none;
    text-shadow: none;
    border: none;
    padding-right: 10px;
}

button:active {
    filter: brightness(80%);
    color: #0ED7BA;
}

textarea{
    display: block;
    padding-top: 15px;
    padding-bottom: 15px;
    padding-left: 10px;
    padding-right: 10px;
}
#input-type-selector{
    /* min-width: 300; */
}
option{
    width: 200;
    /* min-width: 200; */
}
#textOutput{
    /* padding: 20px; */
}

#blocklyDiv {
    /* flex-basis: 100%; */
    height: 400px;
    min-width: 250px;
}

#patternInput, #regexOutput, #textInput, #replacementInput {
    width: 100%;
}

#regexOutput{
    display: flex;
}

#regex-code{
    width: 100%
}

#copy-button{

}

#textInput {
    margin-top: 20px;
    margin-bottom: 20px;
}

#output {
    height: 50%;
}

h1, h2, h3, label {
    color: #FAFAFA;
}

label {
    padding-right: 20px;
}

footer, h6, strong {
    color: #9C9D9F;
}

footer{
    font-size: x-small;
    padding-top: 30px;
    padding-bottom: 10px;
}

#is-error {
    height: 0;
    width: 0;
    visibility: hidden;
}

#js2py, #py2js {
    height: 0;
    width: 0;
    visibility: hidden;
}

summary {
    display: flex;
    align-items: center;
    cursor: pointer;
    color: #9C9D9F;
}

summary:hover {
    color: aqua;
}

details {
    border: 1px solid #3D4044;
    border-radius: 8px;
}

.group {
    padding: 5px;
    margin-left: 30px;
    width: min-content;
}

.group-contents {
    margin-left: 30px;
    em {
        color: #9C9D9F;
    }
}

details[open]>summary {
    border-bottom: 1px solid #aaa;
    margin-bottom: 0.5em;
    color: #fff;
}

hr {
    margin-top: 20px;
    margin-bottom: 20px;
}

input[type="radio"] {
    /* margin: 8px; */
    /* padding: 0px; */
    height: 10px;
}

#radio-group {
    /* display: flex; */
    /* align-items: center; */
}

.radio-container{
    display:flex;
    align-items: top;
    height: 20px;
    /* margin-right: 5px; */
    /* margin-top: 2px; */
    /* margin: 2px; */
    /* padding: 0px; */
}


#error-text {
    color: crimson;
}

textarea,
#blocklyDiv,
input[type="radio"] {
    margin-bottom: 30px;
}

.spread {
    display: flex;
    justify-content: space-between;
}

#dialect-picker {
    align-self: center;
}

#groups-line {
    align-items: center;
    display: flex;
}

#title{
    align-items: center;
    display: flex;
    justify-content: space-between;
}

h1{
    padding-left: 10%;
}

#logo-holder{
    /* position: absolute; */
    /* padding-top: 160px;
    padding-left: 60px;
    z-index: -1;
    scale: 170%;
    opacity: 30%; */
    /* float: right; */
    /* justify-content: ; */
}

#fatty{
    display: flex;
}

#logo{
    scale:  80%;
    /* position: absolute; */
    /* padding-right: -200%; */
    /* width:  80%; */
    /* height: 70%; */
    /* flex-shrink: 10%; */
}
